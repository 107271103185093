/* @font-face {
  font-family: "Monument Grotesk";
  font-weight: bold;
  src: url(../assets/fonts/MonumentGrotesk-Bold.woff) format("woff");
}

@font-face {
  font-family: "Monument Grotesk";
  font-weight: 500;
  src: url(../assets/fonts/MonumentGrotesk-Medium.woff) format("woff");
} */

@font-face {
  font-family: "Monument Grotesk";
  src: url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Bold.eot);
  src: local("Monument Grotesk Bold"), local("MonumentGrotesk-Bold"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Bold.eot?#iefix)
      format("embedded-opentype"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Bold.woff2)
      format("woff2"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Bold.woff)
      format("woff"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Bold.ttf)
      format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Monument Grotesk";
  src: url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Medium.eot);
  src: local("Monument Grotesk Medium"), local("MonumentGrotesk-Medium"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Medium.eot?#iefix)
      format("embedded-opentype"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Medium.woff2)
      format("woff2"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Medium.woff)
      format("woff"),
    url(../assets/fonts/MonumentGrotesk/MonumentGrotesk-Medium.ttf)
      format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* reset */
html,
body {
  box-sizing: border-box;
  height: 100%;
  min-height: inherit;
}

#root {
  min-height: 100vh;
  height: auto;
}

*,
::after,
::before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  font-weight: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Monument Grotesk", helvetica, sans-serif;
}

::-moz-selection {
  /* Code for Firefox */
  background: #003cdc;
  color: #e6f9ef;
}

::selection {
  background: #003cdc;
  color: #e6f9ef;
}

input {
  background-color: transparent;
}

textarea {
  resize: vertical;
}

button:active {
  transform: scale(0.96);
}

/* End of reset */
.rtl {
  direction: rtl;
}

.fs0 {
  font-size: 4rem; /*64px*/
}
.fs1 {
  font-size: 2.125rem; /*34px*/
}
.fs2 {
  font-size: 1.75rem; /*28px*/
}
.fs3 {
  font-size: 1.375rem; /*22px*/
}
.fs4 {
  font-size: 1.125rem; /*18px*/
}
.fs5 {
  font-size: 1rem; /*16px*/
}
.fs6 {
  font-size: 0.875rem; /*14px*/
}
.fs7 {
  font-size: 0.625rem; /*10px*/
}

.fs1-hover:hover {
  font-size: 2.125rem; /*34px*/
}
.fs2-hover:hover {
  font-size: 1.75rem; /*28px*/
}
.fs3-hover:hover {
  font-size: 1.375rem; /*22px*/
}
.fs4-hover:hover {
  font-size: 1.125rem; /*18px*/
}
.fs5-hover:hover {
  font-size: 1rem; /*16px*/
}
.fs6-hover:hover {
  font-size: 0.875rem; /*14px*/
}
.fs7-hover:hover {
  font-size: 0.625rem; /*10px*/
}

.text-transform-none {
  text-transform: none;
}
.text-decoration-none {
  text-decoration: none;
}
.text-decoration-none-hover:hover {
  text-decoration: none;
}
.underline {
  text-decoration: underline;
}
.underline-hover:hover {
  text-decoration: underline;
}
.first-underline::first-letter {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}

.italic {
  font-style: italic;
}
.normal {
  font-style: normal;
}
.capitalize {
  text-transform: capitalize;
}
.first-capitalize::first-letter {
  text-transform: capitalize;
}
.first-capitalize {
  text-transform: lowercase;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}

.thin {
  font-weight: 300;
}
.regular {
  font-weight: normal;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: bold;
}

.letter-spacing {
  letter-spacing: 0.06rem; /*1px*/
}

.font-family-site {
  font-family: "Roboto", helvetica, sans-serif;
}
.font-family-monospace {
  font-family: Consolas, Monaco, monospace;
}
.font-family-title {
  /* font-family: "Monument Grotesk", helvetica, sans-serif; */
  font-family: "Roboto", helvetica, sans-serif;
}
.pre {
  white-space: pre-wrap;
}
.pre-line {
  white-space: pre-line;
}
.white-space {
  white-space: normal;
}

.left-align {
  text-align: left;
}
.center {
  text-align: center;
}
.right-align {
  text-align: right;
}
.justify {
  text-align: justify;
}
.center-last {
  text-align-last: center;
}

.wrap {
  white-space: normal;
}
.nowrap {
  white-space: nowrap;
}
.break-word {
  word-wrap: break-word;
}
.break-all {
  word-break: break-all;
}

.line-height-1 {
  line-height: 1;
}
.line-height-2 {
  line-height: 1.125;
}
.line-height-3 {
  line-height: 1.25;
}
.line-height-4 {
  line-height: 1.5;
}
.line-height-5 {
  line-height: 2;
}
.line-height-6 {
  line-height: 2.25rem;
}

.list-style-none {
  list-style: none;
}

.truncate {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

.left {
  float: left;
}
.right {
  float: right;
}

.fit {
  max-width: 100%;
}
.min-width-full {
  min-width: 100%;
}
.min-width-15rem {
  min-width: 15rem;
}

.min-width-60px {
  min-width: 60px;
}
.min-width-9rem {
  min-width: 144px;
}
.min-width-15rem {
  min-width: 240px;
}

.max-width-1 {
  max-width: 24rem;
}
.max-width-2 {
  max-width: 32rem;
}
.max-width-3 {
  max-width: 48rem;
}
.max-width-4 {
  max-width: 70rem;
}
.max-width-5 {
  max-width: 80rem;
}
.max-width-6 {
  max-width: 90rem;
}
.max-width-7 {
  max-width: 100rem;
}

.width-2 {
  width: 2rem;
}
.width-4 {
  width: 4rem;
}

.no-width {
  width: 0%;
}
.width-auto {
  width: auto;
}

.border-box {
  box-sizing: border-box;
}

.align-baseline {
  vertical-align: baseline;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.align-super {
  vertical-align: super;
}

.btrz-logo {
  background-image: url("../../public/images/logo.jpeg");
  background-repeat: no-repeat;
  background-size: 100%;
  height: 55px;
  width: 225px;
}

/* Use .not-print or add a class to identify elements should't display in print mode */
@media print {
  .no-print {
    display: none;
  }
  .btrz-table {
    page-break-inside: avoid;
  }
}

.column-count-2 {
  column-count: 2;
}
.column-count-3 {
  column-count: 3;
}

.column-count-3 div {
  break-inside: avoid;
}

.column-gap-2 {
  column-gap: 0.5em;
}
.column-gap-3 {
  column-gap: 1em;
}
.column-gap-4 {
  column-gap: 2em;
}
.column-gap-5 {
  column-gap: 4em;
}

.col {
  float: left;
  box-sizing: border-box;
}

.col-right {
  float: right;
  box-sizing: border-box;
}

.col-1 {
  width: 8.33333%;
}
.col-2 {
  width: 16.66667%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333%;
}
.col-5 {
  width: 41.66667%;
}
.col-6 {
  width: 50%;
}
.col-7 {
  width: 58.33333%;
}
.col-8 {
  width: 66.66667%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33333%;
}
.col-11 {
  width: 91.66667%;
}
.col-12 {
  width: 100%;
}

.col-1-from-7 {
  width: 14.285714285714286%; /* 1/7 */
}
.col-5-from-7 {
  width: 71.42857142857143%; /* 5/7 */
}
.col-1-from-5 {
  width: 20%; /* 1/5 */
}

@media (min-width: 641px) {
  .sm-medium {
    font-weight: 500;
  }

  .sm-inline {
    display: inline;
  }
  .sm-block {
    display: block;
  }
  .sm-inline-block {
    display: inline-block;
  }

  .sm-left-align {
    text-align: left;
  }
  .sm-center {
    text-align: center;
  }
  .sm-right-align {
    text-align: right;
  }
  .sm-justify {
    text-align: justify;
  }

  .sm-border-none {
    border: 0 !important;
  }

  .sm-left {
    float: left;
  }
  .sm-right {
    float: right;
  }

  .sm-mtn1 {
    margin-top: -0.25rem;
  }
  .sm-mtn2 {
    margin-top: -0.5rem;
  }
  .sm-mtn3 {
    margin-top: -1rem;
  }
  .sm-mtn4 {
    margin-top: -2rem;
  }
  .sm-mtn5 {
    margin-top: -4rem;
  }

  .sm-mbn1 {
    margin-bottom: -0.25rem;
  }
  .sm-mbn2 {
    margin-bottom: -0.5rem;
  }
  .sm-mbn3 {
    margin-bottom: -1rem;
  }
  .sm-mbn4 {
    margin-bottom: -2rem;
  }
  .sm-mbn5 {
    margin-bottom: -4rem;
  }

  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-right {
    float: right;
    box-sizing: border-box;
  }

  .sm-col-1 {
    width: 8.33333%;
  }

  .sm-col-2 {
    width: 16.66667%;
  }

  .sm-col-3 {
    width: 25%;
  }

  .sm-col-4 {
    width: 33.33333%;
  }

  .sm-col-5 {
    width: 41.66667%;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-7 {
    width: 58.33333%;
  }

  .sm-col-8 {
    width: 66.66667%;
  }

  .sm-col-9 {
    width: 75%;
  }

  .sm-col-10 {
    width: 83.33333%;
  }

  .sm-col-11 {
    width: 91.66667%;
  }

  .sm-col-12 {
    width: 100%;
  }

  .sm-column-count-2 {
    column-count: 2;
  }

  .sm-column-count-3 {
    column-count: 3;
  }

  .sm-inline {
    display: inline;
  }

  .sm-width-4 {
    width: 4rem;
  }
}
@media (min-width: 833px) {
  .md-col {
    float: left;
    box-sizing: border-box;
  }

  .md-col-right {
    float: right;
    box-sizing: border-box;
  }

  .md-col-1 {
    width: 8.33333%;
  }

  .md-col-2 {
    width: 16.66667%;
  }

  .md-col-3 {
    width: 25%;
  }

  .md-col-4 {
    width: 33.33333%;
  }

  .md-col-5 {
    width: 41.66667%;
  }

  .md-col-6 {
    width: 50%;
  }

  .md-col-7 {
    width: 58.33333%;
  }

  .md-col-8 {
    width: 66.66667%;
  }

  .md-col-9 {
    width: 75%;
  }

  .md-col-10 {
    width: 83.33333%;
  }

  .md-col-11 {
    width: 91.66667%;
  }

  .md-col-12 {
    width: 100%;
  }
}
@media (min-width: 1025px) {
  .lg-col {
    float: left;
    box-sizing: border-box;
  }

  .lg-col-right {
    float: right;
    box-sizing: border-box;
  }

  .lg-col-1 {
    width: 8.33333%;
  }

  .lg-col-2 {
    width: 16.66667%;
  }

  .lg-col-3 {
    width: 25%;
  }

  .lg-col-4 {
    width: 33.33333%;
  }

  .lg-col-5 {
    width: 41.66667%;
  }

  .lg-col-6 {
    width: 50%;
  }

  .lg-col-7 {
    width: 58.33333%;
  }

  .lg-col-8 {
    width: 66.66667%;
  }

  .lg-col-9 {
    width: 75%;
  }

  .lg-col-10 {
    width: 83.33333%;
  }

  .lg-col-11 {
    width: 91.66667%;
  }

  .lg-col-12 {
    width: 100%;
  }
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -webkit-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
/* Avoids issues with btrz-pill using inline-block */
.flex.inline-block {
  display: -webkit-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
/* End of Avoids issues ... */

@media (min-width: 641px) {
  .sm-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .sm-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sm-justify-between {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media (min-width: 833px) {
  .md-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 1025px) {
  .lg-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-wrap {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (max-width: 640px) {
  .xs-flex-wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .xs-flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .xs-justify-around {
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}

.column-reverse {
  -webkit-flex-direction: column-reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
.row-reverse {
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.items-start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  -ms-grid-row-align: flex-start;
  align-items: flex-start;
}
.items-end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  -ms-grid-row-align: flex-end;
  align-items: flex-end;
}
.items-center {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
}
.items-baseline {
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  -ms-grid-row-align: baseline;
  align-items: baseline;
}
.items-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  -ms-grid-row-align: stretch;
  align-items: stretch;
}

.self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}
.self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}
.self-baseline {
  -webkit-align-self: baseline;
  -ms-flex-item-align: baseline;
  align-self: baseline;
}
.self-stretch {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}

.justify-start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-center {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.content-start {
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}
.content-end {
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
}
.content-center {
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
.content-between {
  -webkit-align-content: space-between;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}
.content-around {
  -webkit-align-content: space-around;
  -ms-flex-line-pack: distribute;
  align-content: space-around;
}
.content-stretch {
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}
.flex-auto {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}
.flex-none {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.flex-grow-0 {
  -webkit-box-flex-grow: 0;
  -webkit-flex-grow: 0;
  -ms-flex-grow: 0;
  flex-grow: 0;
}
.flex-grow {
  -webkit-box-flex-grow: 1;
  -webkit-flex-grow: 1;
  -ms-flex-grow: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -webkit-box-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-shrink: 0;
  flex-shrink: 0;
}
.flex-shrink {
  -webkit-box-flex-shrink: 1;
  -webkit-flex-shrink: 1;
  -ms-flex-shrink: 1;
  flex-shrink: 1;
}

.order-0 {
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}
.order-last {
  -webkit-box-ordinal-group: 100000;
  -webkit-order: 99999;
  -ms-flex-order: 99999;
  order: 99999;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}
.height0 {
  height: 0px;
}
.height30 {
  height: 30px;
}
.minheight90 {
  min-height: 90px;
}
.minheight450 {
  min-height: 450px;
}
.height-focus:focus {
  height: auto;
}
.height100 {
  height: 100%;
}
.height-auto {
  height: auto;
}
.max-height0 {
  max-height: 0;
}
.max-height500 {
  max-height: 500px;
}
.max-height1000 {
  max-height: 1000px;
}
.min-height500 {
  min-height: 500px;
}
.height200 {
  height: 200px;
}
.minwidth90 {
  min-width: 90px;
}
.width100 {
  width: 100%;
}

.top-0 {
  top: 0;
}
.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}

.top-1 {
  top: 1rem;
}
.top-2 {
  top: 2rem;
}
.top-3 {
  top: 3rem;
}
.top-4 {
  top: 4rem;
}
.top-5 {
  top: 5rem;
}
.top-6 {
  top: 6rem;
}
.top-7 {
  top: 7rem;
}
.top-8 {
  top: 8rem;
}
.top-9 {
  top: 9rem;
}
.top-10 {
  top: 10rem;
}
.top-11 {
  top: 11rem;
}
.top-12 {
  top: 12rem;
}
.top-13 {
  top: 13rem;
}
.top-14 {
  top: 14rem;
}
.top-15 {
  top: 15rem;
}
.top-16 {
  top: 16rem;
}
.top-17 {
  top: 17rem;
}
.top-18 {
  top: 18rem;
}
.top-19 {
  top: 19rem;
}
.top-20 {
  top: 20rem;
}
.top-21 {
  top: 21rem;
}
.top-22 {
  top: 22rem;
}
.top-23 {
  top: 23rem;
}
.top-24 {
  top: 24rem;
}
.top-25 {
  top: 25rem;
}
.top-26 {
  top: 26rem;
}
.top-27 {
  top: 27rem;
}
.top-28 {
  top: 28rem;
}
.top-29 {
  top: 29rem;
}
.top-30 {
  top: 30rem;
}
.top-31 {
  top: 31rem;
}
.top-32 {
  top: 32rem;
}
.top-33 {
  top: 33rem;
}
.top-34 {
  top: 34rem;
}
.top-35 {
  top: 35rem;
}
.top-36 {
  top: 36rem;
}
.top-37 {
  top: 37rem;
}
.top-38 {
  top: 38rem;
}
.top-39 {
  top: 39rem;
}
.top-40 {
  top: 40rem;
}
.top-41 {
  top: 41rem;
}
.top-42 {
  top: 42rem;
}
.top-43 {
  top: 43rem;
}
.top-44 {
  top: 44rem;
}
.top-45 {
  top: 45rem;
}
.top-46 {
  top: 46rem;
}
.top-47 {
  top: 47rem;
}
.top-48 {
  top: 48rem;
}
.top-49 {
  top: 49rem;
}
.top-50 {
  top: 50rem;
}
.top-51 {
  top: 51rem;
}
.top-52 {
  top: 52rem;
}
.top-53 {
  top: 53rem;
}
.top-54 {
  top: 54rem;
}
.top-55 {
  top: 55rem;
}

.right-1 {
  right: 1rem;
}
.bottom-1 {
  bottom: 1rem;
}
.left-1 {
  left: 1rem;
}

.top-3 {
  top: 3rem;
}
.right-3 {
  right: 3rem;
}
.bottom-3 {
  bottom: 3rem;
}
.left-3 {
  left: 3rem;
}

.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
.z5 {
  z-index: 5;
}
.z6 {
  z-index: 6;
}
.z7 {
  z-index: 7;
}
.z8 {
  z-index: 8;
}
.z9 {
  z-index: 9;
}
.z10 {
  z-index: 10;
}
.zmax {
  z-index: 2000;
}

.border {
  border-style: solid;
  border-width: 0.06rem; /*1px*/
}

.border-top {
  border-top-style: solid;
  border-top-width: 0.06rem; /*1px*/
}

.border-right {
  border-right-style: solid;
  border-right-width: 0.06rem; /*1px*/
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 0.06rem; /*1px*/
}

.border-left {
  border-left-style: solid;
  border-left-width: 0.06rem; /*1px*/
}

.border1 {
  border-style: solid;
  border-width: 0.19rem; /*3px*/
}

.border-top1 {
  border-top-style: solid;
  border-top-width: 0.19rem; /*3px*/
}

.border-right1 {
  border-right-style: solid;
  border-right-width: 0.19rem; /*3px*/
}

.border-bottom1 {
  border-bottom-style: solid;
  border-bottom-width: 0.19rem; /*3px*/
}

.border-left1 {
  border-left-style: solid;
  border-left-width: 0.19rem; /*3px*/
}

.box-shadow-none {
  box-shadow: 0 0 0;
}
.box-shadow-inset-none {
  box-shadow: inset 0 0 0;
}
.border-none {
  border: 0;
}
.no-border-at-all {
  border: 0 !important;
}
.border-dashed {
  border-style: dashed;
}

.rounded {
  border-radius: 3px;
}
.circle {
  border-radius: 50%;
}

.rounded-top {
  border-radius: 3px 3px 0 0;
}
.rounded-right {
  border-radius: 0 3px 3px 0;
}
.rounded-bottom {
  border-radius: 0 0 3px 3px;
}
.rounded-left {
  border-radius: 3px 0 0 3px;
}

.not-rounded {
  border-radius: 0;
}

.invisible {
  visibility: hidden;
}
.visible-hover:hover {
  visibility: visible;
}
.invisible-label label {
  visibility: hidden;
}

/* Content isn't displayed on the screen, but is announced to screen reader users */
.hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

@media (max-width: 640px) {
  .xs-hide {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 832px) {
  .sm-hide {
    display: none !important;
  }
}

@media (min-width: 833px) and (max-width: 1024px) {
  .md-hide {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .lg-hide {
    display: none !important;
  }
}

.display-none {
  display: none !important;
}

@media (max-width: 640px) {
  .xs-display-none {
    display: none !important;
  }
}

@media (min-width: 641px) and (max-width: 832px) {
  .sm-display-none {
    display: none !important;
  }
}

@media (min-width: 833px) and (max-width: 1024px) {
  .md-display-none {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .lg-display-none {
    display: none !important;
  }
}

/* Animation */
.btrz-slideInDown--initial {
  margin-top: -100%;
  transition: margin-top 0.25s 0.25s;
}

.no-content-animation--initial * {
  opacity: 0;
  transition: opacity 0.5s 0s;
}

.btrz-slideInDown {
  margin-top: 0;
  transition: margin-top 0.25s;
}

.no-content-animation * {
  opacity: 1;
  transition: opacity 0.25s 0.25s;
}

.animated {
  animation-duration: 500ms;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.longer-duration {
  animation-duration: 1s;
}

.animated.hinge {
  animation-duration: 2s;
}

.transition-min-width {
  transition: min-width 0.3s;
}

.list-move {
  transition: transform 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOutShrinkX {
  0% {
    max-height: 10000px;
    opacity: 1;
    animation-timing-function: ease-out;
  }
  50% {
    max-height: 100px;
    overflow: hidden;
    animation-timing-function: ease-out;
  }
  100% {
    opacity: 0;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;
    border: transparent solid 0;
    animation-timing-function: ease-out;
  }
}

.fadeOutShrinkX {
  animation-name: fadeOutShrinkX;
}

@-webkit-keyframes slideUpFade {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    opacity: 0.1;
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideUpFade {
  from {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
    opacity: 0.1;
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideUpFade {
  animation-name: slideUpFade;
  animation-duration: 0.6s;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    height: auto;
  }

  to {
    opacity: 1;
    height: 0;
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

.slide-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-fade-leave-active {
  transition: all 0s ease-out;
}
.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}
.slide-fade-leave-to {
  opacity: 0;
}

.timer-enter-active {
  animation-name: timerAnimation;
  animation-timing-function: ease-out;
  animation-iteration-count: 2;
}

@keyframes timerAnimation {
  from {
    width: 100%;
    opacity: 0.2;
  }

  to {
    width: 0%;
    opacity: 0.8;
  }
}

.slide-up-fade-enter-active {
  transition: all 0.6s ease;
}
.slide-up-fade-leave-active {
  transition: all 0.6s ease-out;
}
.slide-up-fade-enter {
  transform: translateY(10px);
  opacity: 0;
}
.slide-up-fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.4s ease;
}
.fade-leave-active {
  transition: all 0.4s ease;
}
.fade-enter {
  opacity: 0;
}
.fade-leave-to {
  opacity: 0;
}

.fadeIn-enter-active {
  transition: all 0.4s ease;
}
.fadeIn-leave-active {
  transition: 0;
}
.fadeIn-enter {
  opacity: 0;
}
.fadeIn-leave-to {
  opacity: 0;
}

.scale-out-enter-active {
  transition: all 0.2s ease-in;
}
.scale-out-leave-active {
  transition: all 0.2s ease-out;
}
.scale-out-enter {
  transform: scaleY(0);
}
.scale-out-leave-to {
  transform: scaleY(0);
}

.scaleY-out-enter-active {
  transition: all 0.2s ease-in;
}
.scaleY-out-leave-active {
  transition: all 0.32s ease-out;
}
.scaleY-out-enter {
  transform: scaleY(0);
}
.scaleY-out-leave-to {
  transform: scaleY(0);
}

.strikethrough-enter-active {
  transition: all 0.6s ease;
}
.strikethrough-leave-active {
  transition: all 0.6s ease-out;
}
.strikethrough-enter {
  transform: translateX(10px);
  opacity: 0;
}
.strikethrough-leave-to {
  opacity: 0;
}
.strikethrough-leave-to::before {
  content: "";
  border-bottom: 3px solid #d83a3a;
  width: 100%;
  position: absolute;
  right: 0;
  top: 60%;
}

.rotate90 {
  transform: rotate(90deg);
}

/* end of animation */

.opacity1 {
  opacity: 0.1;
}
.opacity2 {
  opacity: 0.2;
}
.opacity3 {
  opacity: 0.3;
}
.opacity4 {
  opacity: 0.4;
}
.opacity5 {
  opacity: 0.5;
}
.opacity6 {
  opacity: 0.6;
}
.opacity7 {
  opacity: 0.7;
}
.opacity8 {
  opacity: 0.8;
}
.opacity9 {
  opacity: 0.9;
}

.opacity1-hover:hover {
  opacity: 0.1;
}
.opacity2-hover:hover {
  opacity: 0.2;
}
.opacity3-hover:hover {
  opacity: 0.3;
}
.opacity4-hover:hover {
  opacity: 0.4;
}
.opacity5-hover:hover {
  opacity: 0.5;
}
.opacity6-hover:hover {
  opacity: 0.6;
}
.opacity7-hover:hover {
  opacity: 0.7;
}
.opacity8-hover:hover {
  opacity: 0.8;
}
.opacity9-hover:hover {
  opacity: 0.9;
}

.text-indent {
  text-indent: 1.5rem;
}

.mtn50 {
  margin-top: -50%;
}
.mrn50 {
  margin-right: -50%;
}
.mbn50 {
  margin-bottom: -50%;
}
.mln50 {
  margin-left: -50%;
}

.rounded-1 {
  border-radius: 1rem;
}
.rounded-max {
  border-radius: 1000px;
}

/* Mobile only classes */
@media (max-width: 640px) {
  .xs-flex-auto {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-width: 0;
    min-height: 0;
  }
  .xs-truncate {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .xs-bold {
    font-weight: bold;
  }
  .xs-absolute {
    position: absolute;
  }

  .xs-col {
    float: left;
    box-sizing: border-box;
  }
  .xs-col-right {
    float: right;
    box-sizing: border-box;
  }

  .xs-col-1 {
    width: 8.33333%;
  }
  .xs-col-2 {
    width: 16.66667%;
  }
  .xs-col-3 {
    width: 25%;
  }
  .xs-col-4 {
    width: 33.33333%;
  }
  .xs-col-5 {
    width: 41.66667%;
  }
  .xs-col-6 {
    width: 50%;
  }
  .xs-col-7 {
    width: 58.33333%;
  }
  .xs-col-8 {
    width: 66.66667%;
  }
  .xs-col-9 {
    width: 75%;
  }
  .xs-col-10 {
    width: 83.33333%;
  }
  .xs-col-11 {
    width: 91.66667%;
  }
  .xs-col-12 {
    width: 100%;
  }

  .xs-center {
    text-align: center;
  }

  .xs-px3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xs-px4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xs-mr0 {
    margin-right: 0;
  }
  .xs-mb3 {
    margin-bottom: 1rem;
  }
  .xs-mt0 {
    margin-top: 0;
  }
  .xs-pt0 {
    padding-top: 0;
  }

  .xs-mtn1 {
    margin-top: -0.25rem;
  }

  .xs-border-none {
    border: 0;
  }

  .xs-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  /* font sizes */
  .xs-fs0 {
    font-size: 4rem; /*64px*/
  }
  .xs-fs1 {
    font-size: 2.125rem; /*34px*/
  }
  .xs-fs2 {
    font-size: 1.75rem; /*28px*/
  }
  .xs-fs3 {
    font-size: 1.375rem; /*22px*/
  }
  .xs-fs4 {
    font-size: 1.125rem; /*18px*/
  }
  .xs-fs5 {
    font-size: 1rem; /*16px*/
  }
  .xs-fs6 {
    font-size: 0.875rem; /*14px*/
  }
}

.pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-all {
  pointer-events: all;
}
.ew-resize {
  cursor: ew-resize;
}
.grab {
  cursor: grab;
}
.grabbing {
  cursor: grabbing;
}
.pointer-not-allowed {
  cursor: not-allowed;
}

*:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 2px dashed #848484;
  outline-offset: 0.19rem; /*3px*/
  outline: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #333333;
}

.inverted-color select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 white;
}

.inverted-color .outline-grey-lighter:-moz-focusring,
.inverted-color .outline-grey-lighter-focus:-moz-focusring {
  outline: 2px dashed #ffffff;
  outline-offset: 0.19rem; /*3px*/
}

.inverted-color .outline-grey-lighter,
.inverted-color .outline-grey-lighter-focus:focus,
.inverted-color a:focus {
  outline: 2px dashed #ffffff;
  outline-offset: 0.19rem; /*3px*/
}

.outline-grey-lighter:-moz-focusring,
.outline-grey-lighter-focus:-moz-focusring {
  outline: 2px dashed #848484;
  outline-offset: 0.19rem; /*3px*/
}

.outline-grey-lighter,
.outline-grey-lighter-focus:focus,
a:focus {
  outline: 2px dashed #848484;
  outline-offset: 0.19rem; /*3px*/
}

.outline-offset-n3-focus:focus {
  outline-offset: -3px;
}
.outline-offset-n5 {
  outline-offset: -5px;
}

.focus-none:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 0;
}

.box-shadow-focus:focus {
  /* accent color */
  box-shadow: 0 0 5px #4b986d;
}

.box-shadow-inset {
  box-shadow: inset 0 2px 3px rgba(0, 0, 0, 0.5);
}
/* Keeps previous box-shadow definition in buttons */
.box-shadow-inset.bg-black-10-hover:hover,
.box-shadow-inset.bg-black-10-focus:focus {
  box-shadow: inset 0 0 99999px rgba(0, 0, 0, 0.1),
    inset 0 2px 3px rgba(0, 0, 0, 0.5);
}
.box-shadow-inset.box-shadow-focus:focus {
  box-shadow: 0 0 5px #4b986d, inset 0 2px 3px rgba(0, 0, 0, 0.5);
}

/* custom background color */
.bg-background {
  background-color: #f5ebe0;
}
.border-background {
  border-color: #f5ebe0;
}

.bg-semitransparent {
  background-color: rgba(256, 256, 256, 0.25);
}
.bg-transparent {
  background-color: transparent;
}
.color-transparent {
  color: transparent;
}
.border-transparent {
  border-color: transparent;
}

/* accent colors */
.bg-accent-lightest {
  background-color: #edfff5;
}
.color-accent-lightest {
  color: #edfff5;
}
.border-accent-lightest {
  border-color: #edfff5;
}
.color-accent-lightest-hover:hover {
  color: #edfff5;
}

.bg-accent-lighter {
  background-color: #bcedd2;
}
.color-accent-lighter {
  color: #bcedd2;
}
.border-accent-lighter {
  border-color: #bcedd2;
}
.color-accent-lighter-hover:hover {
  color: #bcedd2;
}

.bg-accent-light {
  background-color: #7dc39c;
}
.color-accent-light {
  color: #7dc39c;
}
.border-accent-light {
  border-color: #7dc39c;
}
.color-accent-light-hover:hover {
  color: #7dc39c;
}

.bg-accent {
  background-color: #4b986d;
}
.color-accent {
  color: #4b986d;
}
.border-accent {
  border-color: #4b986d;
}
.color-accent-hover:hover {
  color: #4b986d;
}
.bg-accent-hover:hover {
  background-color: #4b986d;
}

.bg-accent-dark {
  background-color: #256e46;
}
.color-accent-dark {
  color: #256e46;
}
.border-accent-dark {
  border-color: #256e46;
}
.color-accent-dark-hover:hover {
  color: #256e46;
}

.bg-accent-darker {
  background-color: #0d4325;
}
.color-accent-darker {
  color: #0d4325;
}
.border-accent-darker {
  border-color: #0d4325;
}
.color-accent-darker-hover:hover {
  color: #0d4325;
}

.bg-accent-darkest {
  background-color: #01190c;
}
.color-accent-darkest {
  color: #01190c;
}
.border-accent-darkest {
  border-color: #01190c;
}
.color-accent-darkest-hover:hover {
  color: #01190c;
}

.box-shadow-accent {
  box-shadow: 0 0 5px #4b986d;
  border-color: #4b986d !important;
}

.box-shadow-accent-focus:focus {
  box-shadow: 0 0 5px #4b986d;
  border-color: #4b986d;
}
.border-accent-hover:hover {
  border-color: #4b986d;
}
.color-accent-hover:hover {
  color: #4b986d;
}
.outline-accent {
  outline: solid 1px #4b986d;
  outline-offset: -1px;
}
.outline-accent-hover:hover {
  outline: solid 1px #4b986d;
  outline-offset: -1px;
}

/* danger colors */
.bg-danger-lightest {
  background-color: #faecec;
}
.color-danger-lightest {
  color: #faecec;
}
.border-danger-lightest {
  border-color: #faecec;
}
.color-danger-lightest-hover:hover {
  color: #faecec;
}

.bg-danger-lighter {
  background-color: #efacac;
}
.color-danger-lighter {
  color: #efacac;
}
.border-danger-lighter {
  border-color: #efacac;
}
.color-danger-lighter-hover:hover {
  color: #efacac;
}

.bg-danger-light {
  background-color: #e37070;
}
.color-danger-light {
  color: #e37070;
}
.border-danger-light {
  border-color: #e37070;
}
.color-danger-light-hover:hover {
  color: #e37070;
}

.bg-danger {
  background-color: #d83a3a;
}
.color-danger {
  color: #d83a3a;
}
.border-danger {
  border-color: #d83a3a;
}
.color-danger-hover:hover {
  color: #d83a3a;
}
.bg-danger-hover:hover {
  background-color: #d83a3a;
}

.bg-danger-dark {
  background-color: #a42c2c;
}
.color-danger-dark {
  color: #a42c2c;
}
.border-danger-dark {
  border-color: #a42c2c;
}
.color-danger-darker-hover:hover {
  color: #a42c2c;
}

.bg-danger-darker {
  background-color: #6f1e1e;
}
.color-danger-darker {
  color: #6f1e1e;
}
.border-danger-darker {
  border-color: #6f1e1e;
}
.border-danger-darker-hover:hover {
  border-color: #6f1e1e;
}
.color-danger-darker-hover:hover {
  color: #6f1e1e;
}
.box-shadow-danger {
  box-shadow: 0 0 5px #6f1e1e;
}

.bg-danger-darkest {
  background-color: #3b1010;
}
.color-danger-darkest {
  color: #3b1010;
}
.border-danger-darkest {
  border-color: #3b1010;
}
.color-danger-darkest-hover:hover {
  color: #3b1010;
}

/* warning colors */
.bg-warning-lightest {
  background-color: #fdf7ee;
}
.color-warning-lightest {
  color: #fdf7ee;
}
.border-warning-lightest {
  border-color: #fdf7ee;
}
.color-warning-lightest-hover:hover {
  color: #fdf7ee;
}

.bg-warning-lighter {
  background-color: #f9deb7;
}
.color-warning-lighter {
  color: #f9deb7;
}
.border-warning-lighter {
  border-color: #f9deb7;
}
.color-warning-lighter-hover:hover {
  color: #f9deb7;
}

.bg-warning-light {
  background-color: #f4c582;
}
.color-warning-light {
  color: #f4c582;
}
.border-warning-light {
  border-color: #f4c582;
}
.color-warning-light-hover:hover {
  color: #f4c582;
}

.bg-warning {
  background-color: #f0ad4e;
}
.color-warning {
  color: #f0ad4e;
}
.border-warning {
  border-color: #f0ad4e;
}
.color-warning-hover:hover {
  color: #f0ad4e;
}

.bg-warning-dark {
  background-color: #b6843c;
}
.color-warning-dark {
  color: #b6843c;
}
.border-warning-dark {
  border-color: #b6843c;
}
.color-warning-dark-hover:hover {
  color: #b6843c;
}

.bg-warning-darker {
  background-color: #7c5a29;
}
.color-warning-darker {
  color: #7c5a29;
}
.border-warning-darker {
  border-color: #7c5a29;
}
.color-warning-darker-hover:hover {
  color: #7c5a29;
}

.bg-warning-darkest {
  background-color: #423016;
}
.color-warning-darkest {
  color: #423016;
}
.border-warning-darkest {
  border-color: #423016;
}
.color-warning-darkest-hover:hover {
  color: #423016;
}

/* info colors */
.bg-info-lightest {
  background-color: #ecf3f8;
}
.color-info-lightest {
  color: #ecf3f8;
}
.border-info-lightest {
  border-color: #ecf3f8;
}
.color-info-lightest-hover:hover {
  color: #ecf3f8;
}

.bg-info-lighter {
  background-color: #a5c6df;
}
.color-info-lighter {
  color: #a5c6df;
}
.border-info-lighter {
  border-color: #a5c6df;
}
.color-info-lighter-hover:hover {
  color: #a5c6df;
}

.bg-info-light {
  background-color: #699fc7;
}
.color-info-light {
  color: #699fc7;
}
.border-info-light {
  border-color: #699fc7;
}
.color-info-light-hover:hover {
  color: #699fc7;
}

.bg-info {
  background-color: #377bae;
}
.color-info {
  color: #377bae;
}
.border-info {
  border-color: #377bae;
}
.color-info-hover:hover {
  color: #377bae;
}

.bg-info-dark {
  background-color: #2a5f87;
}
.color-info-dark {
  color: #2a5f87;
}
.border-info-dark {
  border-color: #2a5f87;
}
.color-info-dark-hover:hover {
  color: #2a5f87;
}

.bg-info-darker {
  background-color: #1d415c;
}
.color-info-darker {
  color: #1d415c;
}
.border-info-darker {
  border-color: #1d415c;
}
.color-info-darker-hover:hover {
  color: #1d415c;
}

.bg-info-darkest {
  background-color: #0f2331;
}
.color-info-darkest {
  color: #0f2331;
}
.border-info-darkest {
  border-color: #0f2331;
}
.color-info-darkest-hover:hover {
  color: #0f2331;
}

/* success colors */
.bg-success-lightest {
  background-color: #edfff4;
}
.color-success-lightest {
  color: #edfff4;
}
.border-success-lightest {
  border-color: #edfff4;
}
.color-success-lightest-hover:hover {
  color: #edfff4;
}

.bg-success-lighter {
  background-color: #95d7ad;
}
.color-success-lighter {
  color: #95d7ad;
}
.border-success-lighter {
  border-color: #95d7ad;
}
.color-success-lighter-hover:hover {
  color: #95d7ad;
}

.bg-success-light {
  background-color: #4faf72;
}
.color-success-light {
  color: #4faf72;
}
.border-success-light {
  border-color: #4faf72;
}
.color-success-light-hover:hover {
  color: #4faf72;
}

.bg-success {
  background-color: #1d8744;
}
.color-success {
  color: #1d8744;
}
.border-success {
  border-color: #1d8744;
}
.color-success-hover:hover {
  color: #1d8744;
}

.bg-success-dark {
  background-color: #156231;
}
.color-success-dark {
  color: #156231;
}
.border-success-dark {
  border-color: #156231;
}
.color-success-dark-hover:hover {
  color: #156231;
}

.bg-success-darker {
  background-color: #0d3e1f;
}
.color-success-darker {
  color: #0d3e1f;
}
.border-success-darker {
  border-color: #0d3e1f;
}
.color-success-darker-hover:hover {
  color: #0d3e1f;
}

.bg-success-darkest {
  background-color: #05190c;
}
.color-success-darkest {
  color: #05190c;
}
.border-success-darkest {
  border-color: #05190c;
}
.color-success-darkest-hover:hover {
  color: #05190c;
}

/* inverted colors */
.inverted-color select.bg-transparent {
  background-color: rgba(156, 156, 156, 0.4);
}
.inverted-color textarea.bg-transparent {
  background-color: rgba(156, 156, 156, 0.4);
}
.inverted-color input.bg-transparent {
  background-color: rgba(156, 156, 156, 0.4);
}

.inverted-color .box-shadow-focus:focus {
  box-shadow: 0 0 5px #a9d9bf;
}
.inverted-color .color-accent-hover:hover {
  color: #a9d9bf;
}
.inverted-color .color-accent {
  color: #a9d9bf;
}
.inverted-color .border-accent {
  border-color: #a9d9bf;
}
.inverted-color .box-shadow-accent {
  box-shadow: 0 0 5px #a9d9bf;
}

.inverted-color .bg-black {
  background-color: #ffffff;
}
.inverted-color .color-black {
  color: #ffffff;
}
.inverted-color .border-black {
  border-color: #ffffff;
}
.inverted-color .text-shadow-black {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.66);
}
.inverted-color .bg-black-10-hover:hover {
  box-shadow: inset 0 0 99999px rgba(0, 0, 0, 0.1);
}
.inverted-color .border-black-hover:hover {
  border-color: #ffffff;
}
.inverted-color .color-black-hover:hover {
  color: #ffffff;
}

.inverted-color.box-shadow-black-10 {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.inverted-color .bg-grey-darker {
  background-color: #cfcfcf;
}
.inverted-color .color-grey-darker {
  color: #cfcfcf;
}
.inverted-color .border-grey-darker {
  border-color: #cfcfcf;
}
.inverted-color .border-grey-darker-hover:hover {
  border-color: #cfcfcf;
}
.inverted-color .color-grey-darker-hover:hover {
  color: #cfcfcf;
}

.inverted-color .bg-grey-darkest {
  background-color: #ffffff;
}
.inverted-color .color-grey-darkest {
  color: #ffffff;
}
.inverted-color .border-grey-darkest {
  border-color: #ffffff;
}
.inverted-color .border-grey-darkest-hover:hover {
  border-color: #ffffff;
}
.inverted-color .color-grey-darkest-hover:hover {
  color: #ffffff;
}

.inverted-color.bg-white {
  background-color: #3d3d3d;
}
.inverted-color .bg-white {
  background-color: #3d3d3d;
}
.inverted-color .color-white {
  color: #000000;
}
.inverted-color .border-white {
  border-color: #000000;
}
.inverted-color .bg-white-hover:hover {
  box-shadow: inset 0 0 99999px rgba(255, 255, 255, 0.1);
}
.inverted-color .border-white-hover:hover {
  border-color: #000000;
}
.inverted-color .color-white-hover:hover {
  color: #000000;
}

.inverted-color .bg-grey-lightest {
  background-color: #848484;
}
.inverted-color .color-grey-lightest {
  color: #848484;
}
.inverted-color .border-grey-lightest {
  border-color: #848484;
}
.inverted-color .border-grey-lightest-hover:hover {
  border-color: #848484;
}
.inverted-color .color-grey-lightest-hover:hover {
  color: #848484;
}

.inverted-color .bg-grey-lighter {
  background-color: #e2e2e2;
}
.inverted-color .color-grey-lighter {
  color: #e2e2e2;
}
.inverted-color .border-grey-lighter {
  border-color: #e2e2e2;
}
.inverted-color .border-grey-lighter-hover:hover {
  border-color: #e2e2e2;
}
.inverted-color .color-grey-lighter-hover:hover {
  color: #e2e2e2;
}

.inverted-color .bg-grey {
  background-color: #e2e2e2;
}
.inverted-color .color-grey {
  color: #e2e2e2;
}
.inverted-color .border-grey {
  border-color: #e2e2e2;
}
.inverted-color .border-grey-hover:hover {
  border-color: #e2e2e2;
}
.inverted-color .color-grey-hover:hover {
  color: #e2e2e2;
}

.inverted-color .bg-danger-lightest {
  background-color: #3b1010;
}

.inverted-color .bg-info {
  background-color: #699fc7;
}
.inverted-color .color-info {
  color: #699fc7;
}
.inverted-color .border-info {
  border-color: #699fc7;
}
.inverted-color .color-info-hover:hover {
  color: #699fc7;
}

.inverted-color .cell {
  color: #ffffff;
}
.inverted-color header {
  color: #ffffff;
}

.inverted-color select option {
  background-color: #333;
}

/* black & white */
.bg-white {
  background-color: #ffffff;
}
.color-white {
  color: #ffffff;
}
.border-white {
  border-color: #ffffff;
}
.bg-white-hover:hover {
  box-shadow: inset 0 0 99999px rgba(255, 255, 255, 0.1);
}
.border-white-hover:hover {
  border-color: #ffffff;
}
.color-white-hover:hover {
  color: #ffffff;
}

.bg-black {
  background-color: #000000;
}
.color-black {
  color: #000000;
}
.border-black {
  border-color: #000000;
}
.text-shadow-black {
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.66);
}
.bg-black-10-hover:hover,
.bg-black-10-focus:focus {
  box-shadow: inset 0 0 99999px rgba(0, 0, 0, 0.1);
}
.border-black-hover:hover {
  border-color: #000000;
}
.color-black-hover:hover {
  color: #000000;
}

.bg-black-30 {
  background-color: rgba(0, 0, 0, 0.3);
}

/* box shadows */
.box-shadow-black-10 {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}
.box-shadow-black-10-double {
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.2), 0 2px 3px rgba(0, 0, 0, 0.1);
}

.box-shadow-black-30 {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}
.box-shadow-black-30-hover:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}

.box-shadow-black-50 {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}
.box-shadow-black-50-hover:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}
/* Solves shadow conflict on hover */
.bg-black-10-hover.box-shadow-black-50:hover {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5), inset 0 0 99999px rgba(0, 0, 0, 0.1);
}

/* grey */
.bg-grey-lightest {
  background-color: #efefef;
}
.color-grey-lightest {
  color: #efefef;
}
.border-grey-lightest {
  border-color: #efefef;
}
.bg-grey-lightest-hover:hover {
  box-shadow: inset 0 0 99999px #efefef;
}
.border-grey-lightest-hover:hover {
  border-color: #efefef;
}
.color-grey-lightest-hover:hover {
  color: #efefef;
}

.bg-grey-lighter {
  background-color: #cbcbcb;
}
.color-grey-lighter {
  color: #cbcbcb;
}
.border-grey-lighter {
  border-color: #cbcbcb;
}
.bg-grey-lighter-hover:hover {
  box-shadow: inset 0 0 99999px #cbcbcb;
}
.border-grey-lighter-hover:hover {
  border-color: #cbcbcb;
}
.color-grey-lighter-hover:hover {
  color: #cbcbcb;
}

.bg-grey-light {
  background-color: #a8a8a8;
}
.color-grey-light {
  color: #a8a8a8;
}
.border-grey-light {
  border-color: #a8a8a8;
}
.bg-grey-light-hover:hover {
  box-shadow: inset 0 0 99999px #a8a8a8;
}
.border-grey-light-hover:hover {
  border-color: #a8a8a8;
}
.color-grey-light-hover:hover {
  color: #a8a8a8;
}

.bg-grey {
  background-color: #848484;
}
.color-grey {
  color: #848484;
}
.border-grey {
  border-color: #848484;
}
.bg-grey-hover:hover {
  box-shadow: inset 0 0 99999px #848484;
}
.border-grey-hover:hover {
  border-color: #848484;
}
.color-grey-hover:hover {
  color: #848484;
}

.bg-grey-dark {
  background-color: #606060;
}
.color-grey-dark {
  color: #606060;
}
.border-grey-dark {
  border-color: #606060;
}
.border-grey-dark-hover:hover {
  border-color: #606060;
}
.color-grey-dark-hover:hover {
  color: #606060;
}

.bg-grey-darker {
  background-color: #3d3d3d;
}
.color-grey-darker {
  color: #3d3d3d;
}
.border-grey-darker {
  border-color: #3d3d3d;
}
.border-grey-darker-hover:hover {
  border-color: #3d3d3d;
}
.color-grey-darker-hover:hover {
  color: #3d3d3d;
}

.bg-grey-darkest {
  background-color: #191919;
}
.color-grey-darkest {
  color: #191919;
}
.border-grey-darkest {
  border-color: #191919;
}
.border-grey-darkest-hover:hover {
  border-color: #191919;
}
.color-grey-darkest-hover:hover {
  color: #191919;
}

.transition-all {
  transition: all 300ms;
}

/* HALL OF SHAME */

/* fix manifest screen only */
#fixer-manifest-wrapper label {
  width: auto;
}
#fixer-manifest-wrapper #filter > div > div > div {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
#fixer-manifest-wrapper #filter input.sm-fs5 {
  font-size: 1rem;
  box-shadow: none;
  height: 36px;
  padding: 1rem;
}
#fixer-manifest-wrapper > section {
  box-shadow: none;
}
/* UI changes bulk-seat-selection */
#bulk-seatmap-container #seatmapContainer .btrz-card {
  position: relative !important;
  box-shadow: none !important;
  padding-top: 0 !important;
  padding-left: 0 !important;
}
.checkIn-i {
  padding: 1rem !important;
  border: none !important;
}

/* Force font size in btrz-display size="large" component to fit its container */
.forceFontSize .sm-fs1 {
  font-size: 1.8vw;
}

/* Time-picker */
.btrz-select-form.hour select {
  padding-right: 0;
}
.btrz-select-form.hour div {
  border: none !important;
}
.btrz-select-form.minute select {
  padding-left: 0;
}
.btrz-select-form.minute div {
  border: none !important;
}
.inverted-color .btrz-time-picker .flex.bg-transparent {
  background-color: rgba(156, 156, 156, 0.4);
}
.inverted-color .hour .bg-transparent,
.inverted-color .minute .bg-transparent {
  background-color: transparent;
}

/* styles for links within error messages  */
.btrz-error a {
  color: white;
  text-decoration: underline;
}

/* styles for links within hints  */
.italic.color-grey a {
  color: #848484;
  text-decoration: underline;
}

/* styles for disabled components  */
select:disabled {
  background-color: #efefef;
  color: #191919;
  border-color: #efefef;
}

select:disabled:hover {
  border-color: #efefef;
}

/* 5 day outlook column enlargement  */
@media (min-width: 641px) {
  .wide .col-1-from-5.selectedColumn {
    width: 40%;
    transition: width 300ms;
  }
  .wide .col-1-from-5 {
    width: 15%;
    transition: width 300ms;
  }
  .ssr-amount {
    display: none;
  }
  .selectedColumn .ssr-amount {
    display: inline-block;
  }
}

.ff-max-height {
  /* Fix wrong select's height calculaton in firefox where instead of 57.3px FF renders 59.3px height */
  max-height: calc((1.15 * 1em) + 2rem);
}

/* Custom padding right needed only for btrz-select component */
.jpr2-5 {
  padding-right: 2.5rem;
}

/* checkbox component */
input[type="checkbox"]:checked ~ div.rounded i.fa-square-o {
  display: none;
}
input[type="checkbox"]:checked ~ div.rounded {
  border: solid 1px #efefef;
}
input[type="checkbox"]:not(:checked) ~ div.rounded i.fa-check-square-o {
  display: none;
}
input[type="checkbox"]:disabled + div.rounded {
  background-color: #efefef;
  border-color: #efefef;
  color: #848484;
}

/* No border standalone checkbox */
input[type="checkbox"]:checked ~ div.line-height-1 i.fa-square-o {
  display: none;
}
input[type="checkbox"]:not(:checked) ~ div.line-height-1 i.fa-check-square-o {
  display: none;
}
input[type="checkbox"]:disabled + div.line-height-1 i {
  opacity: 0.5;
}
input[type="checkbox"]:focus ~ div.line-height-1 {
  outline: 2px dashed #848484;
  outline-offset: 6px;
}

/* standalone checkbox and radio */
input[type="checkbox"]:before,
input[type="radio"]:before {
  content: "";
  width: 0.81rem;
  height: 0.81rem;
  background-color: white;
  border-radius: 0.19rem; /*3px*/
  position: absolute;
}

input[type="checkbox"]:checked:after,
input[type="radio"]:checked:after {
  font-family: FontAwesome;
  position: absolute;
  margin: -0.13rem 0 0 -0.065rem;
  color: #4b986d;
}
input[type="checkbox"]:checked:after {
  content: "\f046";
}
input[type="radio"]:checked:after {
  content: "\f192";
}
input[type="checkbox"]:not(:checked):after {
  content: "\f096";
  color: #848484;
  font-family: FontAwesome;
  position: absolute;
  margin: -0.13rem 0 0 -0.065rem;
}
input[type="radio"]:not(:checked):after {
  content: "\f10c";
  color: #848484;
  font-family: FontAwesome;
  position: absolute;
  margin: -0.13rem 0 0 -0.065rem;
}

/* radio component */
input[type="radio"]:checked ~ div.rounded i.fa-circle-o {
  display: none;
}
input[type="radio"]:checked ~ div.rounded {
  border: solid 1px #efefef;
}
input[type="radio"]:not(:checked) ~ div.rounded i.fa-dot-circle-o {
  display: none;
}
input[type="radio"]:disabled + div.rounded {
  background-color: #efefef;
  border-color: #efefef;
  color: #848484;
}

/* change border color if btrzInput component is empty and not disabled */
input:not([disabled]):placeholder-shown {
  border-color: #848484;
}

/* search box component */
.search-box.search-input-open {
  min-width: calc(100% - 100px);
}

@media (min-width: 641px) {
  .search-box.search-input-open {
    min-width: 220px;
  }
}

/* fix hover background on absolute position element */
.bg-black-10-hover .bg-black-10-hover {
  box-shadow: -16px 0 8px white;
}
.bg-black-10-hover:hover .bg-black-10-hover,
.bg-black-10-hover:focus .bg-black-10-hover {
  box-shadow: inset 0 0 99999px rgba(0, 0, 0, 0.1);
}

/* table  */
/* th.truncate, td.truncate { max-width:220px; } */

/* squares */
.square-width {
  width: 3.69rem;
}
.square-width62px {
  width: 3.88rem;
}
.square-width36px {
  width: 2.25rem;
}

.remove-mb4-last-child:last-child {
  margin-bottom: -2rem;
}

.remove-mb-last-child:last-child {
  margin-bottom: 0;
}

/* arrows :before */
.arrow-left-info:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #387eb2;
  position: absolute;
  left: -8px;
  top: calc(50% - 8px);
}

/* Corner decoration */
.glow {
  position: relative;
}
.glow:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 125px solid transparent;
  border-bottom: 0px solid transparent;
  border-right: 1600px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.1;
}

.stops-route-stop {
  margin-top: -3px;
  height: 6px;
  width: 6px;
}

/* button progress bar */
button::before {
  content: "";
  height: 4px;
  width: 0%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.progress-bar::before {
  transition: all 25s;
  width: 100%;
  background-color: #4b986d;
}

/* sticky top relation with top main menu */
.below-top-menu {
  top: 3.31rem; /*53px 53/16*/
}

@media (min-width: 641px) {
  .below-top-menu {
    top: 4.31rem; /*69px 69/16*/
  }
}

/* sticky submenu */
.sticky-submenu {
  top: 3rem;
  box-shadow: 0 0 0.19rem 0.25rem #f5ebe0;
}

@media (min-width: 641px) {
  .sticky-submenu {
    top: 5rem;
    box-shadow: 0 0 0.19rem 0.81rem #f5ebe0;
  }
}

/* sticky thead */
.sticky-header th:not(.table-hack) {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 67px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 1px 0 #848484;
}

.frozen-column tr th:first-of-type,
.frozen-column tr td:first-of-type {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  box-shadow: 1px 0px 1px #cbcbcb;
}

.frozen-column tr th:first-of-type {
  z-index: 3 !important;
}

.frozen-column tr td:first-of-type {
  z-index: 1;
}

/* btrz-table component styles */
.sticky-th {
  box-shadow: 0 1px 0 #848484;
}

.frozen {
  box-shadow: 1px 0px 0 #cbcbcb;
}

.sticky-th.frozen {
  box-shadow: 1px 0px 0 #cbcbcb, 0 1px 0 #848484;
}

/* Shopping cart */

.white-stripes::before {
  content: "";
  background-color: white;
  position: absolute;
  top: 0.06rem; /*1px*/
  left: -1px;
  right: 0;
  height: 1rem;
}

.white-stripes::after {
  content: "";
  background-color: white;
  position: absolute;
  bottom: 0;
  left: -1px;
  right: 0;
  height: 1rem;
}

.mt67px {
  margin-top: 67px;
}
.mt100px {
  margin-top: 100px;
}

/* Dotted borders */
.dotted-top-border {
  /* Some browsers need border-style and border-color default definition */
  border-style: dotted;
  /* border-image: url("images/dots.svg"); */
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  border-width: 8px 0 0 0;
}

.dotted-right-border {
  /* Some browsers need border-style and border-color default definition */
  border-style: dotted;
  /* border-image: url("images/dots.svg"); */
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  border-width: 0 8px 0 0;
}
.dotted-bottom-border {
  /* Some browsers need border-style and border-color default definition */
  border-style: dotted;
  /* border-image: url("images/dots.svg"); */
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  border-width: 0 0 8px 0;
}
.dotted-left-border {
  /* Some browsers need border-style and border-color default definition */
  border-style: dotted;
  /* border-image: url("images/dots.svg"); */
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  border-width: 0 0 0 8px;
}
.dotted-vertical-borders {
  /* Some browsers need border-style and border-color default definition */
  border-style: dotted;
  /* border-image: url("images/dots.svg"); */
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  border-width: 0 8px 0 8px;
}

/* Add male female icon to fontawesome to be used in amenities */
.fa-malefemale:before {
  content: "\f183 \f182";
}

/* 6 points icon for draggable objects */
.fa-ellipsis-v2:before {
  content: "\f142";
}
.fa-ellipsis-v2:after {
  content: "\f142";
  padding-left: 0.06rem; /*1px*/
}

.test {
  background-color: red;
}

/* Icon definition added to font awesome */
.fa-multiple-tickets:before {
  content: "\f145";
  text-shadow: 0.3em 0 0 rgba(207, 207, 207, 0.7), 0.6em 0 0 #cfcfcf;
  text-shadow: 0.3em 0 0 rgba(207, 207, 207, 0.7),
    0.6em 0 0 rgba(207, 207, 207, 0.4);
  padding-right: 0.3em;
  margin-left: -0.3em;
}

.placeholder {
  background-color: #efefef;
  opacity: 0.8;
  background-image: linear-gradient(0deg, #efefef 50%, #fff 50%);
  background-size: 10px 6px;
  box-shadow: inset 20px 24px 24px white;
}

/* content placeholder */
.placeholder-animation {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: 8;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;
  background: #eeeeee;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#eeeeee),
    color-stop(0.2, #e2e2e2),
    color-stop(0.4, #eeeeee),
    to(#eeeeee)
  );
  background-image: -webkit-linear-gradient(
    left,
    #eeeeee 0%,
    #e2e2e2 20%,
    #eeeeee 40%,
    #eeeeee 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  position: relative;
}

@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

/* Stripes animation */

@keyframes animate-stripes {
  0% {
    background-position: 60px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.animated-stripes {
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  animation: animate-stripes 0.6s linear infinite;
}

/* do not change border color in controls after interaction */
.permanent-border .border-grey-lightest {
  border-color: #848484;
}
.inverted-color .permanent-border .border-grey-lightest {
  border-color: #ffffff;
}

/* Requires no background element */
.scroll-shadow {
  overflow-x: auto;
  overflow-y: hidden;
  background-image: linear-gradient(to right, white, white),
    linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}
.scroll-shadow-v {
  overflow-y: auto;
  overflow-x: hidden;
  background-image: linear-gradient(to top, white, white),
    linear-gradient(to top, white, white),
    linear-gradient(to top, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position: bottom center, top center, bottom center, top center;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}
/* radial shadow works better for nono bordered containers*/
.scroll-shadow-vertical {
  overflow-y: auto;
  overflow-x: hidden;
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;
}

.scroll-shadow-horizontal {
  overflow-y: hidden;
  overflow-x: auto;
  background: linear-gradient(to right, white 70%, rgba(255, 255, 255, 0)),
    linear-gradient(to right, rgba(255, 255, 255, 0), white 30%),
    radial-gradient(
      farthest-side at 0 50%,
      rgba(0, 0, 0, 0.15),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 100% 50%,
        rgba(0, 0, 0, 0.15),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-position: left center, right top, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

/* Exact padding right to avoid overlapping custom arrow */
select.pr4 {
  padding-right: 3rem;
}

/* Add an comment sign in a table cell */
.asterisk {
  position: relative;
}

.asterisk::before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 0;
  border-color: transparent #e37070 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  color: #e37070;
}

.cell-border-right {
  box-shadow: inset -1px 0 0px #848484;
}

.v2::after {
  content: "2";
  position: absolute;
  margin-top: -3px;
  margin-left: 5px;
  font-size: 0.8em;
}

/* Triangle Table */
:root {
  --scrollLeft: 0px;
}

.table-hack {
  padding-left: calc((100vw - 60rem) / 2); /* if variables are not supported */
  padding-left: calc((100vw - 60rem - (var(--scrollLeft) * 2)) / 2);
  padding-left: calc((6rem - (var(--scrollLeft) * 2)) / 2);
  box-shadow: -3px 0 5px #f5ebe0;
  z-index: 3;
}

td.hover:not(.bg-background) {
  background-color: #bcedd2;
}

.triangle tr:hover td:first-child,
.triangle th:not(.table-hack).hover {
  color: #edfff5;
  background-color: #191919;
}

.triangle tr:hover td:first-child a,
.hover .col-title {
  color: #edfff5;
}

.triangle tr:hover td:not(.bg-background):not(:first-child) {
  background-color: #bcedd2;
}
.triangle tr:hover td:not(.bg-background):not(:first-child):hover {
  background-color: #edfff5;
}
.triangle td {
  transition: background-color 333ms;
}
.col-title {
  white-space: normal;
  width: 80px;
  max-height: 50px;
  overflow: hidden;
  display: block;
}
.row-title {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}
/* End of Triangle Table */

/* Sandbox label */
.sandbox-label::before {
  content: "";
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 18px solid #003ed8;
  position: absolute;
  left: -18px;
  top: -24px;
}

.sandbox-label::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-left: 18px solid #003ed8;
  position: absolute;
  right: -18px;
  top: -24px;
}
/* End of Sandbox label */

/* Modifiers screen table */
.left-n2px {
  left: -2px;
}
.mt-157 {
  margin-top: 157px;
}
.top-222 {
  top: 222px;
}
.msj-medio {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 40em) {
  .xs-mt-131 {
    margin-top: 131px;
  }
  .xs-mt-196 {
    top: 196px;
  }
}
/* This is to change padding in notifications component for this case specifically */
.mdfrs.p3 {
  padding: 0 1rem;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  height: 52px;
}
/* End of Modifiers screen table */

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.darken-hover:hover {
  filter: brightness(96%);
}
.darken-row-hover:hover td {
  filter: brightness(96%);
}
/* use a class to exclude other tables */
/* table tr:hover td {
  filter: brightness(96%);
} */

/* CSS tooltip */
[data-title] {
  position: relative;
}

[data-title]:hover::after {
  content: attr(data-title);
  position: absolute;
  font-size: 1rem;
  font-style: normal;
  background-color: #3d3d3d;
  color: #efefef;
  border-radius: 0.19rem; /*3px*/
  padding: 6px 12px;
  top: 28px;
  left: 8px;
  animation: fadeInTooltip 3000ms cubic-bezier(1, -0.3, 0.39, 1.2) normal;
  -webkit-animation: fadeInTooltip 900ms cubic-bezier(1, -0.3, 0.39, 1.2) normal;
  z-index: 1;
}

@keyframes fadeInTooltip {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  66% {
    opacity: 0;
    transform: translateY(-8px);
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInTooltip {
  0% {
    opacity: 0;
    transform: translateY(-8px);
  }
  66% {
    opacity: 0;
    transform: translateY(-8px);
  }
  100% {
    opacity: 1;
  }
}

/* link hover underline animation */
.underline-animation {
  display: inline-block;
  position: relative;
}

.underline-animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  left: 0;
  background-color: #4b986d;
  transition: transform 0.16s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.underline-animation:hover::after {
  transform: scaleX(1);
  transition: transform 0.24s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.link-animation:hover {
  text-decoration: none !important;
}
.link-animation::after {
  height: 0.06rem; /*1px*/
  bottom: 2px;
}

.tab-animation:hover {
  border: none !important;
}
.tab-animation::after {
  height: 0.06rem; /*1px*/
  bottom: -1px;
}

.menu-animation:hover {
  border-color: transparent !important;
}
.menu-animation::after {
  height: 0.19rem; /*3px*/
  top: -3px;
}

.shortcut {
  font-size: 0.85em;
  font-family: monospace;
  background: rgba(230, 230, 230, 0.8);
  padding: 0px 3px;
  border-radius: 3px;
  color: #191919;
  margin-left: 2px;
  display: inline-block;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  /* text-transform: uppercase; */
}

.bg-accent .shortcut {
  color: #4b986d;
  /* background:#7DC39C; */
}
