.seatmap-editor {
  display: none;
  padding: 10px;
  position: fixed;
  top: 100px;
  width: 20%;
  z-index: 5000;
}
.seatmap-section {
  display: inline-block;
  padding: 25px 20px 40px 17px;
  background-image: url(https://s3.amazonaws.com/btrz-images-sandbox2/d400bf50-b7f6-11ed-b596-c5e29cb3cc99.jpg);
  background-size: 100% 100%;
  min-height: 230px;
}
.seatmap-section.ui-draggable:hover {
  cursor: move;
  /*background-color: #efefef;*/
}
.seatmap-section-row {
  clear: both;
  float: none;
  display: block;
  margin-bottom: 0px;
  line-height: 10px;
}
.seatmap-select .seatmap-section-row {
  margin-bottom: 0;
}
.seatmap-section-row-handler {
  cursor: pointer;
  display: inline-block;
  font-size: 10px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  vertical-align: middle;
  width: 24px;
  border: solid 1px silver;
  border-radius: 5px;
  margin-left: 3px;
  background-color: #ebebeb;
  color: #4747c4;
}
.seatmap-section-row-left {
  text-align: left;
}
.seatmap-section-row-right {
  text-align: right;
}
.seatmap-section-row-center {
  text-align: center;
}
.seatmap-section-row-seat-number-left {
  margin-left: 1px;
}
.seatmap-section-row-seat-number-right {
  margin-right: 1px;
}
.seatmap-section-row-seat {
  border: 1px solid #333333;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin: 0 0.5px;
  position: relative;
}
.ui-draggable .seatmap-section-row-seat,
.seatmap-select .seatmap-section-row-seat {
  font-size: 6px;
  height: 9px;
  line-height: 10px;
  width: 13px;
  cursor: inherit;
}
.ui-draggable .seatmap-section-row-label,
.seatmap-select .seatmap-section-row-label {
  font-size: 6px;
  height: 9px;
  line-height: 10px;
  width: 17px;
  cursor: inherit;
}
.seatmap-section-row-seat-accessible:before {
  font-family: "Conv_DejaVuSans";
  font-style: normal;
  speak: none;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  content: "\267F";
}
.seatmap-section-row-seat-gap {
  border: medium none;
  padding: 1px;
}
.seatmap-section-row-seat-reserved:before {
  content: "X";
  font-size: 10px;
  line-height: 14px;
}
.seatmap-select .seatmap-section-row-seat-reserved,
.seatmap-select .seatmap-section-row-seat-gap {
  cursor: default !important;
}
.ui-draggable .seatmap-section-row-seat-reserved:before,
.seatmap-select .seatmap-section-row-seat-reserved:before {
  font-size: 13px;
}

#seatmaps-header {
  min-height: 90px;
  border-bottom: 1px solid #bfbfbf;
  width: 945px;
  margin-left: 1px;
}
#seatmaps-header .seatmap-details {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
#seatmaps-header .seatmap-details label {
  margin-right: 10px;
}
#seatmaps-header .left-side {
  float: left;
}
#seatmaps-header .right-side {
  float: right;
}
#seatColor .add-on.icon-tint,
#fontColor .add-on.icon-tint,
#bgColor .add-on.icon-tint {
  position: initial;
}
.seatmap-preview {
  padding-top: 10px;
}
.edit-seat {
  position: absolute;
  top: -235px;
  left: -100px;
  z-index: 1;
}
.edit-seat .seatmap-editor-modal {
  padding: 5px;
  display: inline-block;
  width: 250px;
  margin-bottom: 0;
}
.edit-seat .seatmap-editor-modal hr {
  margin: 10px;
}
.edit-seat .seatmap-editor-modal .row {
  display: block;
}
.edit-seat .seatmap-editor-modal .row span {
  line-height: 30px;
  float: left;
  font-size: 14px;
}
.edit-seat .seatmap-editor-modal .row input {
  margin-left: 25px;
  float: left;
  background: white;
  padding: 12px 2px;
}
.edit-seat .seatmap-editor-modal .row select,
.edit-seat .seatmap-editor-modal .row input {
  font-size: revert;
}
.seatmap-container {
  background-color: #f5f5f5 !important;
  font-family: "Roboto", sans-serif;
  position: relative;
  height: 450px;
  width: 100%;
  border: none;
  padding: 1px;
}
.seatmap-section-row-seat-focus {
  box-shadow: 0 0 1px 2px #4b986d;
}
.seatmap-container .seatmap-components-focus {
  outline: dashed #848484 2px;
  outline-offset: -6px;
}
.seatmap-container .seatmap-components {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
  background-image: url(/public/images/seatmap_grid.png);
  background-repeat: repeat;
}
.seatmap-container .seatmap-components .components {
  position: absolute;
  width: 100%;
  height: 100%;
}
.seatmap-container .seatmap-components .components .component {
  position: absolute;
  /* display: inline-table; */
  /* min-width: 95px; */
}
.seatmap-container .seatmap-components .components .component a.remove,
.seatmap-container .seatmap-components .components .component a.view,
.seatmap-container .seatmap-components .components .component a.duplicate,
.seatmap-container
  .seatmap-components
  .components
  .component
  a.duplicate-closer,
.seatmap-container .seatmap-components .components .component a.drag-anchor,
.seatmap-container .seatmap-components .components .component a.edit {
  position: absolute;
  cursor: pointer;
  background-color: white;
  padding: 0 3px;
}
.seatmap-container .seatmap-components .components .component a.remove {
  left: 27px;
  top: -15px;
  font-size: 10px;
}
.seatmap-container .seatmap-components .components .component a.edit {
  left: 7px;
  font-size: 10px;
  top: -15px;
}
.seatmap-container .seatmap-components .components .component a.view {
  left: 64px;
  font-size: 10px;
  top: -15px;
}
.seatmap-container .seatmap-components .components .component a.duplicate {
  left: 90px;
  font-size: 10px;
  top: -15px;
}

.seatmap-container
  .seatmap-components
  .components
  .component
  a.duplicate-closer {
  left: 65px;
  font-size: 10px;
  top: -15px;
}

.seatmap-container .seatmap-components .components .component a.drag-anchor {
  left: 110px;
  font-size: 10px;
  top: -15px;
}

.seatmap-container .seatmap-components .components .component a.rotate-pin {
  position: absolute;
  top: -10px;
  left: -10px;
  width: 10px;
  height: 10px;
  background-color: #81f577;
  border-radius: 6px;
  border: solid 1px #25aa25;
}
.seatmap-picture {
  resize: both;
  overflow: hidden;
  border: 1px solid transparent;
}
.seatmap-picture img {
  width: 98%;
  height: 100%;
}
.seatmap-picture.no-resizable {
  resize: none;
}
.seatmap-label {
  position: relative;
}
.seatmap-label a.remove,
.seatmap-label a.view,
.seatmap-label a.edit {
  position: absolute;
  cursor: pointer;
  top: -35px;
}
.seatmap-label span {
  border-radius: 6px;
  padding: 5px 15px;
  display: inline-block;
  white-space: nowrap;
  text-align: left;
}
.seatmap-section .seatmap-section-label {
  font-size: 10px;
  margin: 0px 0 0px 0px;
  min-height: 13px;
  text-align: left;
}
.seatmap-section .seatmap-section-row-label {
  background-color: #ffffff;
  border-radius: 5px 5px 5px 5px;
  display: inline-block;
  font-size: 10px;
  line-height: 15px;
  text-align: center;
  white-space: nowrap;
  width: 36px;
  margin-right: 0px;
}
.seatmap-section .seatmap-section-row-label input {
  background: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
  text-align: center;
  line-height: 0px;
}
.seatmap-section .seatmap-section-row-seat a {
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}
.seatmap-section .seatmap-section-row-seat a:not(.gaps):hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.seatmap-section .seatmap-section-row-seat a.accessibles:before {
  font-family: "Conv_DejaVuSans";
  font-style: normal;
  speak: none;
  font-weight: normal;
  line-height: 1;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  content: "\267F";
}
.seatmap-section .seatmap-section-row-seat a.gaps {
  border: medium none;
}
.seatmap-section .seatmap-section-row-seat a.reserved:before {
  content: "X";
}
.seatmap-section .seatmap-section-row-seat a.blocked {
  background-color: #b3b1b1;
  cursor: default;
}
.seatmap-section .seatmap-section-row-seat a.selected {
  background-color: green;
  color: silver;
}
.seatmap-section .seatmap-section-row-seat a.taken {
  background-color: red;
  color: silver;
}

.seatmap-section .seatmap-section-row-seat .keyboard:focus {
  background-color: #f26522;
  outline: none;
}
.seatmap-section .rows-container-xlarge .seatmap-section-row {
  margin-bottom: 2px;
}
.seatmap-section
  .rows-container-xlarge
  .seatmap-section-row
  .seatmap-section-row-label {
  font-size: 8px;
  line-height: 10px;
  width: 10px;
  display: inline-table;
}
.seatmap-section
  .rows-container-xlarge
  .seatmap-section-row
  .seatmap-section-row-label
  input {
  width: 11px;
  font-size: 8px;
}
.seatmap-section .rows-container-xlarge .seatmap-section-row .seats-container {
  display: inline-table;
}
.seatmap-section
  .rows-container-xlarge
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat {
  font-size: 9px;
  height: 14px;
  line-height: 14px;
  width: 14px;
}
.seatmap-section
  .rows-container-xlarge
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.accessibles:before {
  font-size: 15px;
}
.seatmap-section
  .rows-container-xlarge
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.reserved:before {
  font-size: 10px;
  line-height: 14px;
}
.seatmap-section .rows-container-large .rows .seatmap-section-row {
  margin-bottom: 1px;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seatmap-section-row-label {
  font-size: 7px;
  line-height: 10px;
  width: 9px;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seatmap-section-row-label
  input {
  width: 9px;
  font-size: 7px;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seats-container {
  display: inline-table;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat {
  font-size: 8px;
  height: 10px;
  line-height: 11px;
  width: 12px;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.accessibles:before {
  font-size: 11px;
}
.seatmap-section
  .rows-container-large
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.reserved:before {
  font-size: 11px;
  line-height: 11px;
}
.seatmap-section .rows-container .rows .seatmap-section-row {
  margin-bottom: 0px;
  line-height: 9px;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seatmap-section-row-label {
  font-size: 6px;
  line-height: 10px;
  width: 7px;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seatmap-section-row-label
  input {
  width: 7px;
  font-size: 6px;
}
.seatmap-section .rows-container .rows .seatmap-section-row .seats-container {
  display: inline-table;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat {
  font-size: 7px;
  height: 8px;
  line-height: 9px;
  width: 10px;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.accessibles:before {
  font-size: 9px;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.gaps {
  border: medium none;
}
.seatmap-section
  .rows-container
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.reserved:before {
  font-size: 9px;
  line-height: 9px;
}
.seatmap-section .rows-container-small .rows .seatmap-section-row {
  margin-bottom: 0px;
  line-height: 3px;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seatmap-section-row-label {
  font-size: 4px;
  line-height: 3px;
  width: 6px;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seatmap-section-row-label
  input {
  width: 6px;
  font-size: 4px;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container {
  display: inline-table;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat {
  font-size: 0px;
  height: 6px;
  width: 6px;
  margin-right: 1px;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.accessibles:before {
  font-size: 8px;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.gaps {
  border: medium none;
}
.seatmap-section
  .rows-container-small
  .rows
  .seatmap-section-row
  .seats-container
  .seatmap-section-row-seat
  a.reserved:before {
  font-size: 9px;
  line-height: 9px;
}
.seatmap-container-iframe {
  overflow: hidden;
}
.seatmap-container-embed {
  position: absolute;
  height: 97%;
  width: 99%;
  border: none;
  padding: 0px;
  top: 0;
  left: 0;
}
.seatmap-container-embed .seatmap-components {
  background-image: none !important;
}

#seatmap-iframe .component {
  width: 100%;
}

.seat-hover {
  border: solid 1px #1d8744;
  background-color: white;
  color: #1d8744;
}

.seat-disponible {
  border-color: rgb(56, 126, 178);
  background-color: rgb(56, 126, 178);
  color: #ffffff;
}

.img-map img,
.img-map .seatmap-picture {
  width: 256px !important;
  height: 800px !important;
}
