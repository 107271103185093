.datepicker-rct,
.datepicker-rct input,
.react-datepicker-wrapper,
.react-datepicker-popper,
.react-datepicker__month-container,
.react-datepicker {
  width: 100% !important;
  font-family: "Roboto", helvetica, sans-serif !important;
  text-transform: capitalize;
}

.react-datepicker__current-month {
  font-family: "Roboto", helvetica, sans-serif !important;
  font-weight: 400 !important;
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  font-size: 1rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  height: 40px;
  line-height: 40px;
  border-color: white !important;
  border-style: solid !important;
  border-width: 0.06rem !important;
  cursor: pointer !important;
  width: calc(100% / 7) !important;
  font-size: 0.875rem /*14px*/;
  margin: 0 !important;
}

.react-datepicker__day {
  height: 40px;
  line-height: 40px;
  border-color: white !important;
  border-style: solid !important;
  border-width: 0.06rem !important;
  cursor: pointer !important;
  width: calc(100% / 7) !important;
  font-size: 1rem /*14px*/;
  margin: 0 !important;
}

.react-datepicker__week {
  height: 40px !important;
  align-items: center;
}

.react-datepicker__day:hover {
  border-color: #4b986d !important;
  border-style: solid;
  border-width: 0.06rem;
  font-weight: 700 !important;
  background-color: white !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
  font-family: "Roboto", helvetica, sans-serif !important;
}

.react-datepicker__day--outside-month {
  visibility: hidden !important;
}

.react-datepicker__navigation {
  padding-top: 1.5rem !important;
  padding-bottom: 0.5rem !important;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 2px !important;
}

.react-datepicker-popper[data-placement^="top"] {
  padding-bottom: 0px !important;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #4b986d !important;
}

.react-datepicker__close-icon {
  right: 45px;
}

.react-datepicker__close-icon::after {
  background-color: #cbcbcb;
}

.react-datepicker__close-icon:hover::after {
  background-color: #848484;
}
