table {
  border-spacing: 0;
  border-collapse: collapse;
  font-variant-numeric: lining-nums tabular-nums;
}

.table {
  display: table;
}
.table-cell {
  display: table-cell;
}

@media (min-width: 641px) {
  .sm-table {
    display: table;
  }
  .sm-table-cell {
    display: table-cell;
  }
}

/*This is to override older CSS definition. Hopefully it will be removed in near future.*/
.btrz-table.table-sorter thead tr .header-sort-down,
.btrz-table.table-sorter thead tr .header-sort-up {
  background-image: none;
}

.btrz-table tbody tr.selected input[type="checkbox"]:before,
.btrz-table tbody tr.selected input[type="radio"]:before {
  background-color: #bcedd2;
}
.btrz-table tbody tr:not(.no-hover):hover td input[type="radio"]:before {
  background-color: #efefef;
}
.btrz-table
  tbody
  tr.selected:not(.no-hover):hover
  td
  input[type="radio"]:before {
  background-color: #bcedd2;
}

@media only screen and (max-width: 767px) {
  /* Force table to not be like tables anymore */
  .no-more-tables table,
  .no-more-tables thead,
  .no-more-tables tbody,
  .no-more-tables th,
  .no-more-tables td,
  .no-more-tables tr {
    display: block;
    /* font-size: 15px; */
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  .no-more-tables thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .no-more-tables tr {
    margin-bottom: 2rem;
  }

  .no-more-tables td {
    /* Behave  like a "row" */
    border: none;
    position: relative;
    white-space: normal;
    text-align: left;
  }

  .no-more-tables td:before {
    /* Now like a table header */
    position: absolute;
    font-weight: 500;
  }

  /*
    Label the data
    */
  .no-more-tables td:not(.accionable):before {
    content: attr(data-prop);
    margin-top: -1.5rem;
  }
}

/* /////////////
// BTRZ-TABLE //
///////////// */
.btrz-table {
  width: 100%;
  font-family: "Roboto", helvetica, sans-serif;
  border: 0;
  color: #191919;
}

.btrz-table thead th {
  text-align: left;
  font-weight: 500; /* medium */
  font-size: 1rem; /* sm-fs5 */
  padding: 1rem 1rem 0.5rem 1rem; /* pb2 pt3 px3 */
}

.header-sort-down:after {
  content: "\f0d7";
  font-family: fontawesome;
  padding-left: 0.5rem;
}

.header-sort-up:after {
  content: "\f0d8";
  font-family: fontawesome;
  padding-left: 0.5rem;
}

.header-draggable {
  cursor: grabbing;
}

.header-sortable-action {
  cursor: pointer;
}

/* sticky thead */
.btrz-table.table-sticky thead {
  z-index: 2000; /* zmax */
  top: 222px; /* top-222 */
}
@media (max-width: 40em) {
  .btrz-table.table-sticky thead {
    top: 196px; /* .xs-mt-196 */
  }
}
.btrz-table.table-sticky thead th {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 51px; /* below-top-menu */
  z-index: 2;
  background-color: #ffffff;
}
.overflow-auto .btrz-table.table-sticky thead th,
.overflow-x-auto .btrz-table.table-sticky thead th,
.overflow-y-auto .btrz-table.table-sticky thead th {
  top: 0px;
}
@media (min-width: 641px) {
  .btrz-table.table-sticky thead th {
    top: 67px; /* below-top-menu */
  }
  .overflow-auto .btrz-table.table-sticky thead th,
  .overflow-x-auto .btrz-table.table-sticky thead th,
  .overflow-y-auto .btrz-table.table-sticky thead th {
    top: 0px;
  }
}
.btrz-table.table-sticky.table-standalone thead th {
  background-color: #f5ebe0; /* bg-background */
}
.btrz-table.table-sticky.table-standalone thead th::after,
.btrz-table.table-sticky.table-x-border thead th::after {
  content: "";
  width: 100%;
  background-color: #848484;
  height: 0.06rem; /*1px*/
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
}

/* Frozen column */
.btrz-table.frozen-first-column tr th:first-of-type,
.btrz-table.frozen-first-column tr td:first-of-type {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  left: 0;
}
.btrz-table.frozen-first-column tr td:first-of-type::before {
  content: "";
  width: 0.06rem; /*1px*/
  background-color: #ffffff;
  height: 100%;
  position: absolute;
  left: -1px;
  top: 0;
  bottom: 0;
}
.btrz-table.frozen-first-column tr td:first-of-type::after {
  content: "";
  width: 0.06rem; /*1px*/
  background-color: #cbcbcb;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
}
.btrz-table.table-standalone.frozen-first-column tr th:first-of-type::before {
  content: "";
  width: 0.06rem; /*1px*/
  background-color: #ecdecf;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
}
.btrz-table.frozen-first-column tr th:first-of-type::before {
  content: "";
  width: 0.06rem; /*1px*/
  background-color: #ebebeb;
  height: 100%;
  position: absolute;
  right: -1px;
  top: 0;
  bottom: 0;
}
.btrz-table.frozen-first-column tr th:first-of-type {
  z-index: 3;
}
.btrz-table.frozen-first-column:not(.table-standalone) tr th:first-of-type {
  background-color: white;
}
.btrz-table.frozen-first-column.table-standalone tr th:first-of-type {
  background-color: #f5ebe0; /* bg-background */
}

/* cells */
.btrz-table tbody td {
  text-align: left;
  font-size: 1.125rem; /* sm-fs4 */
  border: solid 1px #cbcbcb;
  padding: 1rem;
  /* background-color: white; */
}

.btrz-table tbody td:not(.align-middle) {
  vertical-align: top;
}

.btrz-table.align-middle tbody td {
  vertical-align: middle;
}

/* TABLE-X-BORDER: horizontal borders only */
.btrz-table.table-x-border tbody td {
  border: none;
}
.btrz-table.table-x-border tbody td:not(.border-none) {
  border-bottom: solid 1px #cbcbcb;
}
.btrz-table.table-x-border thead th {
  border: none;
  border-bottom: solid 1px #848484;
}

/* SMALL TABLE */
.btrz-table.table-small thead th {
  font-size: 0.875rem; /* fs6 */
  padding: 1rem 1rem 0.25rem 1rem; /* pb1 pt3 px3 */
}
.btrz-table.table-small tbody td {
  font-size: 1rem; /* sm-fs5 */
  padding: 0.5rem 1rem;
}

/* row hover  */
.btrz-table tbody tr:not(.no-hover):hover td {
  background-color: #efefef;
}
/* cell hover */
.btrz-table:not(.table-x-border)
  tbody
  tr:not(.no-hover)
  td:not(.no-hover):hover {
  box-shadow: inset 0 0px 0px 999px #ffffff;
  box-shadow: inset 0 0px 0px 999px #3d3d3d;
  color: white;
}

/* SELECTED ROW */
/* selected row background  */
.btrz-table tbody tr.selected td {
  background-color: #edfff4;
}
/* selected row hover  */
.btrz-table tbody tr.selected:not(.no-hover):hover td {
  background-color: #bcedd2;
}
/* selected cell hover */
.btrz-table:not(.table-x-border)
  tbody
  tr.selected:not(.no-hover)
  td:not(.no-hover):hover {
  box-shadow: inset 0 0px 0px 999px #edfff4;
  box-shadow: inset 0 0px 0px 999px #0d4325;
  color: white;
}

/* STRIPED TABLE */
/* odd row */
.btrz-table.table-striped > tbody > tr:nth-of-type(odd) td {
  background-color: #f9f9f9;
}
/* odd row hover */
.btrz-table.table-striped
  > tbody
  > tr:nth-of-type(odd):not(.no-hover):hover
  td {
  background-color: #e2e0e0;
}
/* odd cell hover */
.btrz-table.table-striped
  > tbody
  > tr:nth-of-type(odd):not(.no-hover)
  td:not(.no-hover):hover {
  box-shadow: inset 0 0px 0px 999px #f9f9f9;
  box-shadow: inset 0 0px 0px 999px #191919;
  color: white;
}

/* table outline */
.btrz-table:not(.table-standalone) tbody tr:first-child td:first-child {
  border-top-left-radius: 0.19rem; /*3px*/
}
.btrz-table:not(.table-standalone) tbody tr:first-child td:last-child {
  border-top-right-radius: 0.19rem; /*3px*/
}
.btrz-table:not(.table-standalone) tbody tr:last-child td:first-child {
  border-bottom-left-radius: 0.19rem; /*3px*/
}
.btrz-table:not(.table-standalone) tbody tr:last-child td:last-child {
  border-bottom-right-radius: 0.19rem; /*3px*/
}
.btrz-table:not(.table-standalone):not(.table-x-border) tbody {
  outline: solid 1px #848484;
  outline-offset: -1px;
  border-radius: 0.19rem; /*3px*/
  border: solid 2px white;
}
.btrz-table .btrz-table.table-standalone tbody,
.btrz-table .btrz-table.table-x-border tbody {
  outline: none;
}

/* links within tables */
.btrz-table a.color-danger:hover {
  color: #d83a3a;
}
.btrz-table a:not(.btn):not(.text-decoration-none) {
  color: #191919;
  text-decoration: underline;
}
.btrz-table a:not(.btn):not(.text-decoration-none):hover {
  color: #4b986d;
}
.btrz-table:not(.table-x-border)
  tbody
  tr:not(.no-hover)
  td:not(.no-hover):hover
  a:not(.btn) {
  color: white;
}

/* btrz-table modifiers */
.btrz-table thead th.right-align,
.btrz-table thead th.right {
  text-align: right;
}
.btrz-table thead th.center {
  text-align: center;
}
.btrz-table tbody th.wrap,
.btrz-table tbody th.white-space {
  white-space: normal;
}
.btrz-table tbody td.right-align,
.btrz-table tbody td.right {
  text-align: right;
}
.btrz-table tbody td.center {
  text-align: center;
}
.btrz-table tbody td.wrap,
.btrz-table tbody td.white-space {
  white-space: normal;
}
.btrz-table .cell1 {
  min-width: 10px;
  width: 10px;
}
.btrz-table .cell2 {
  min-width: 20px;
  width: 20px;
}
.btrz-table .cell3 {
  min-width: 30px;
  width: 30px;
}
.btrz-table .cell4 {
  min-width: 40px;
  width: 40px;
}
.btrz-table .cell5 {
  min-width: 50px;
  width: 50px;
}
.btrz-table .cell6 {
  min-width: 60px;
  width: 60px;
}
.btrz-table .cell7 {
  min-width: 70px;
  width: 70px;
}
.btrz-table .cell8 {
  min-width: 80px;
  width: 80px;
}
.btrz-table .cell9 {
  min-width: 90px;
  width: 90px;
}
.btrz-table .cell10 {
  min-width: 100px;
  width: 100px;
}
.btrz-table .cell11 {
  min-width: 110px;
  width: 110px;
}
.btrz-table .cell12 {
  min-width: 120px;
  width: 120px;
}
.btrz-table .cell13 {
  min-width: 130px;
  width: 130px;
}
.btrz-table .cell14 {
  min-width: 140px;
  width: 140px;
}
.btrz-table .cell15 {
  min-width: 150px;
  width: 150px;
}
.btrz-table .cell16 {
  min-width: 160px;
  width: 160px;
}
.btrz-table .cell17 {
  min-width: 170px;
  width: 170px;
}
.btrz-table .cell18 {
  min-width: 180px;
  width: 180px;
}
.btrz-table .cell19 {
  min-width: 190px;
  width: 190px;
}
.btrz-table .cell20 {
  min-width: 200px;
  width: 200px;
}
.btrz-table .cell21 {
  min-width: 210px;
  width: 210px;
}
.btrz-table .cell22 {
  min-width: 220px;
  width: 220px;
}
.btrz-table .cell23 {
  min-width: 230px;
  width: 230px;
}
.btrz-table .cell24 {
  min-width: 240px;
  width: 240px;
}
