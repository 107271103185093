.mb1px {
  margin-bottom: 0.06rem; /*1px*/
}

.mt05 {
  margin-top: 0.125rem;
}

.m0 {
  margin: 0;
}
.mt0 {
  margin-top: 0;
}
.mr0 {
  margin-right: 0;
}
.mb0 {
  margin-bottom: 0;
}
.ml0 {
  margin-left: 0;
}
.mx0 {
  margin-left: 0;
  margin-right: 0;
}
.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.m1 {
  margin: 0.25rem;
}
.mt1 {
  margin-top: 0.25rem;
}
.mr1 {
  margin-right: 0.25rem;
}
.mb1 {
  margin-bottom: 0.25rem;
}
.ml1 {
  margin-left: 0.25rem;
}
.mx1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.m2 {
  margin: 0.5rem;
}
.mt2 {
  margin-top: 0.5rem;
}
.mr2 {
  margin-right: 0.5rem;
}
.mb2 {
  margin-bottom: 0.5rem;
}
.ml2 {
  margin-left: 0.5rem;
}
.mx2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.m3 {
  margin: 1rem;
}
.mt3 {
  margin-top: 1rem;
}
.mr3 {
  margin-right: 1rem;
}
.mb3 {
  margin-bottom: 1rem;
}
.ml3 {
  margin-left: 1rem;
}
.mx3 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.m4 {
  margin: 2rem;
}
.mt4 {
  margin-top: 2rem;
}
.mr4 {
  margin-right: 2rem;
}
.mb4 {
  margin-bottom: 2rem;
}
.ml4 {
  margin-left: 2rem;
}
.mx4 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my4 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.m5 {
  margin: 4rem;
}
.mt5 {
  margin-top: 4rem;
}
.mr5 {
  margin-right: 4rem;
}
.mb5 {
  margin-bottom: 4rem;
}
.ml5 {
  margin-left: 4rem;
}
.mx5 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my5 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mxn1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.mxn2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.mxn3 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.mxn4 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.mxn5 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.myn1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.myn2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.myn3 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.myn4 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.myn5 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.mtn1 {
  margin-top: -0.25rem;
}
.mtn2 {
  margin-top: -0.5rem;
}
.mtn3 {
  margin-top: -1rem;
}
.mtn4 {
  margin-top: -2rem;
}
.mtn5 {
  margin-top: -4rem;
}

.mrn1 {
  margin-right: -0.25rem;
}
.mrn2 {
  margin-right: -0.5rem;
}
.mrn3 {
  margin-right: -1rem;
}
.mrn4 {
  margin-right: -2rem;
}
.mrn5 {
  margin-right: -4rem;
}

.mbn1 {
  margin-bottom: -0.25rem;
}
.mbn2 {
  margin-bottom: -0.5rem;
}
.mbn3 {
  margin-bottom: -1rem;
}
.mbn4 {
  margin-bottom: -2rem;
}
.mbn5 {
  margin-bottom: -4rem;
}

.mln1 {
  margin-left: -0.25rem;
}
.mln2 {
  margin-left: -0.5rem;
}
.mln3 {
  margin-left: -1rem;
}
.mln4 {
  margin-left: -2rem;
}
.mln5 {
  margin-left: -4rem;
}

.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.no-padding-at-all {
  padding: 0 !important;
}

.p0 {
  padding: 0;
}
.pt0 {
  padding-top: 0;
}
.pr0 {
  padding-right: 0;
}
.pb0 {
  padding-bottom: 0;
}
.pl0 {
  padding-left: 0;
}
.px0 {
  padding-left: 0;
  padding-right: 0;
}
.py0 {
  padding-top: 0;
  padding-bottom: 0;
}

.p1 {
  padding: 0.25rem;
}
.pt1 {
  padding-top: 0.25rem;
}
.pr1 {
  padding-right: 0.25rem;
}
.pb1 {
  padding-bottom: 0.25rem;
}
.pl1 {
  padding-left: 0.25rem;
}
.px1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.p2 {
  padding: 0.5rem;
}
.pt2 {
  padding-top: 0.5rem;
}
.pr2 {
  padding-right: 0.5rem;
}
.pb2 {
  padding-bottom: 0.5rem;
}

.pb200 {
  padding-bottom: 200px;
}

.pl2 {
  padding-left: 0.5rem;
}
.py2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.p3 {
  padding: 1rem;
}
.pt3 {
  padding-top: 1rem;
}
.pr3 {
  padding-right: 1rem;
}
.pb3 {
  padding-bottom: 1rem;
}
.pl3 {
  padding-left: 1rem;
}
.py3 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px3 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.p4 {
  padding: 2rem;
}
.pt4 {
  padding-top: 2rem;
}
.pr4 {
  padding-right: 2rem;
}
.pb4 {
  padding-bottom: 2rem;
}
.pl4 {
  padding-left: 2rem;
}
.py4 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px4 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.p5 {
  padding: 4rem;
}
.pt5 {
  padding-top: 4rem;
}
.pr5 {
  padding-right: 4rem;
}
.pb5 {
  padding-bottom: 4rem;
}
.pl5 {
  padding-left: 4rem;
}
.py5 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.px5 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.gap1 {
  gap: 0.25rem;
}
.gap2 {
  gap: 0.5rem;
}
.gap3 {
  gap: 1rem;
}
.gap4 {
  gap: 2rem;
}
.gap5 {
  gap: 4rem;
}

@media (min-width: 641px) {
  .sm-regular {
    font-weight: normal;
  }

  .sm-center {
    text-align: center;
  }

  .sm-uppercase {
    text-transform: uppercase;
  }

  .sm-mx3 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm-m0 {
    margin: 0;
  }
  .sm-mt0 {
    margin-top: 0;
  }
  .sm-mr0 {
    margin-right: 0;
  }
  .sm-mb0 {
    margin-bottom: 0;
  }
  .sm-ml0 {
    margin-left: 0;
  }
  .sm-mx0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm-my0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm-m1 {
    margin: 0.25rem;
  }
  .sm-mt1 {
    margin-top: 0.25rem;
  }
  .sm-mr1 {
    margin-right: 0.25rem;
  }
  .sm-mb1 {
    margin-bottom: 0.25rem;
  }
  .sm-ml1 {
    margin-left: 0.25rem;
  }
  .sm-mx1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm-my1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm-m2 {
    margin: 0.5rem;
  }
  .sm-mt2 {
    margin-top: 0.5rem;
  }
  .sm-mr2 {
    margin-right: 0.5rem;
  }
  .sm-mb2 {
    margin-bottom: 0.5rem;
  }
  .sm-ml2 {
    margin-left: 0.5rem;
  }
  .sm-mx2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm-my2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm-m3 {
    margin: 1rem;
  }
  .sm-mt3 {
    margin-top: 1rem;
  }
  .sm-mr3 {
    margin-right: 1rem;
  }
  .sm-mb3 {
    margin-bottom: 1rem;
  }
  .sm-ml3 {
    margin-left: 1rem;
  }
  .sm-mx3 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm-my3 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm-m4 {
    margin: 2rem;
  }
  .sm-mt4 {
    margin-top: 2rem;
  }
  .sm-mr4 {
    margin-right: 2rem;
  }
  .sm-mb4 {
    margin-bottom: 2rem;
  }
  .sm-ml4 {
    margin-left: 2rem;
  }
  .sm-mx4 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm-my4 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm-m5 {
    margin: 4rem;
  }
  .sm-mt5 {
    margin-top: 4rem;
  }
  .sm-mr5 {
    margin-right: 4rem;
  }
  .sm-mb5 {
    margin-bottom: 4rem;
  }
  .sm-ml5 {
    margin-left: 4rem;
  }
  .sm-mx5 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .sm-my5 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm-mxn1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .sm-mxn2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .sm-mxn3 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm-mxn4 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .sm-mxn5 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm-ml-auto {
    margin-left: auto;
  }
  .sm-mr-auto {
    margin-right: auto;
  }
  .sm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm-p0 {
    padding: 0;
  }
  .sm-pt0 {
    padding-top: 0;
  }
  .sm-pr0 {
    padding-right: 0;
  }
  .sm-pb0 {
    padding-bottom: 0;
  }
  .sm-pl0 {
    padding-left: 0;
  }
  .sm-px0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm-py0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm-p1 {
    padding: 0.25rem;
  }
  .sm-pt1 {
    padding-top: 0.25rem;
  }
  .sm-pr1 {
    padding-right: 0.25rem;
  }
  .sm-pb1 {
    padding-bottom: 0.25rem;
  }
  .sm-pl1 {
    padding-left: 0.25rem;
  }
  .sm-px1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm-py1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm-p2 {
    padding: 0.5rem;
  }
  .sm-pt2 {
    padding-top: 0.5rem;
  }
  .sm-pr2 {
    padding-right: 0.5rem;
  }
  .sm-pb2 {
    padding-bottom: 0.5rem;
  }
  .sm-pl2 {
    padding-left: 0.5rem;
  }
  .sm-py2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm-px2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm-p3 {
    padding: 1rem;
  }
  .sm-pt3 {
    padding-top: 1rem;
  }
  .sm-pr3 {
    padding-right: 1rem;
  }
  .sm-pb3 {
    padding-bottom: 1rem;
  }
  .sm-pl3 {
    padding-left: 1rem;
  }
  .sm-py3 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm-px3 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm-p4 {
    padding: 2rem;
  }
  .sm-pt4 {
    padding-top: 2rem;
  }
  .sm-pr4 {
    padding-right: 2rem;
  }
  .sm-pb4 {
    padding-bottom: 2rem;
  }
  .sm-pl4 {
    padding-left: 2rem;
  }
  .sm-py4 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm-px4 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm-p5 {
    padding: 4rem;
  }
  .sm-pt5 {
    padding-top: 4rem;
  }
  .sm-pr5 {
    padding-right: 4rem;
  }
  .sm-pb5 {
    padding-bottom: 4rem;
  }
  .sm-pl5 {
    padding-left: 4rem;
  }
  .sm-py5 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm-px5 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  /* font sizes */
  .sm-fs0 {
    font-size: 4rem; /*64px*/
  }
  .sm-fs1 {
    font-size: 2.125rem; /*34px*/
  }
  .sm-fs2 {
    font-size: 1.75rem; /*28px*/
  }
  .sm-fs3 {
    font-size: 1.375rem; /*22px*/
  }
  .sm-fs4 {
    font-size: 1.125rem; /*18px*/
  }
  .sm-fs5 {
    font-size: 1rem; /*16px*/
  }
  .sm-fs6 {
    font-size: 0.875rem; /*14px*/
  }
}
