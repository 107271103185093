.container {
  position: absolute;
  background: white;
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0.7;
}

.loader {
  border: 16px solid #f3f3f3; /* Círculo exterior */
  border-top: 16px solid #3498db; /* Círculo interior con color azul */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1.5s linear infinite;
  margin: 280px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
